import { Newspaper } from "@mui/icons-material";
// import { MessagePage } from "../pages/message-page";s
import { LandingPage } from "../pages/landing-page";
import { BackgroundIntroductionPage } from "../pages/background-introduction-page";
import { ARSSPPage } from "../pages/ar-ssp-page";
import { ARTMPage } from "../pages/ar-tm-page";
import { ModelPreviewPage } from "../pages/model-preview-page";
import { ARPage } from "../pages/ar-page";
import { WebXRPage } from "../pages/webxr-page";
import { ListingPage } from "../pages/listing-page";

const routes = [
  {
    name: "Home",
    key: "home-page",
    icon: <Newspaper />,
    route: "/",
    routeNav: "/",
    component: <LandingPage />,
  },
  {
    name: "Introduction",
    key: "background-introduction-page",
    icon: <Newspaper />,
    route: "/background-introduction-page/",
    routeNav: "/background-introduction-page",
    component: <BackgroundIntroductionPage />,
  },
  {
    name: "AR",
    key: "ar",
    icon: <Newspaper />,
    route: "/ar/:region/:id",
    routeNav: "/ar",
    component: <ARPage />,
  },
  // {
  //   name: "AR SSP",
  //   key: "ar-ssp-page",
  //   icon: <Newspaper />,
  //   route: "/ar-ssp-page/",
  //   routeNav: "/ar-ssp-page",
  //   component: <ARSSPPage />,
  // },
  // {
  //   name: "AR Tuen Mun",
  //   key: "ar-tm-page",
  //   icon: <Newspaper />,
  //   route: "/ar-tm-page/",
  //   routeNav: "/ar-tm-page",
  //   component: <ARTMPage />,
  // },
  {
    name: "Model Preview",
    key: "model-preview-page",
    icon: <Newspaper />,
    route: "/model-preview-page/",
    routeNav: "/model-preview-page",
    component: <ModelPreviewPage />,
  },
  {
    name: "AR Page",
    key: "ar-page",
    icon: <Newspaper />,
    route: "/ar-page/:region",
    routeNav: "/ar-page",
    component: <ListingPage />,
  },

  // {
  //   name: "AR Page",
  //   key: "ar-page",
  //   icon: <Newspaper />,
  //   route: "/ar-page/",
  //   routeNav: "/ar-page",
  //   component: <ARPage />,
  // },
  // {
  //   name: "XR Demo",
  //   key: "xr-page",
  //   icon: <Newspaper />,
  //   route: "/xr-page/",
  //   routeNav: "/xr-page",
  //   component: <WebXRPage />,
  // },
];

export default routes;
