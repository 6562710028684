// const CryptoJS = require("crypto-js");
import SHA512 from "crypto-js/sha512";
import Hex from "crypto-js/enc-hex";
import { AppSingleton } from "../services/user_config";
import routes from "../basic/routes";

let appSingleton = new AppSingleton();

const getRoute = (key) => {
  const a = routes.filter((_route) => {
    return _route.key === key;
  })[0];
  return a.routeNav;
};

const updateArrayByKey = ({ array, obj, key, val, defaultVal }) => {
  return array.map((el) => {
    const returnValue = { ...el };
    if (el === obj) {
      returnValue[key] = val;
    } else if (defaultVal !== undefined) {
      returnValue[key] = defaultVal;
    }
    return returnValue;
  });
};

const timestampToDate = (ts) => {
  const date = new Date(ts * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedTime = `${year}-${month}-${day}`;
  return formattedTime;
};

const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const sha512 = (raw) => SHA512(raw).toString(Hex);
const clearAccessToken = () => localStorage.setItem("jwt", "");
const getAccessToken = () => localStorage.getItem("jwt");
const updateJwt = (accessToken) => {
  clearAccessToken();
  localStorage.setItem("jwt", accessToken);
};

const serializedChatSonic = (input) => {
  var serialized = [];
  input.split("event:").forEach((e) => {
    const eventAndData = e.split("data:");
    var event = "",
      data = "";
    eventAndData.forEach((el, i) => {
      // console.log(el);
      if (i === 0) {
        event += el.replaceAll("\r", "");
      } else {
        // data += el.replaceAll("\n", "");
        data += el.replaceAll("\r\n\r\n", "").replace(" ", "");
      }
    });
    var d = {
      event: event,
      data: data,
    };
    serialized.push(d);
  });
  return serialized;
};

const rgb2Hls = ({ r, g, b }) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  let h, l, s;
  if (max === min) {
    h = 0; // achromatic (no hue)
  } else {
    switch (max) {
      case r:
        h = ((g - b) / diff + (g < b ? 6 : 0)) / 6;
        break;
      case g:
        h = ((b - r) / diff + 2) / 6;
        break;
      case b:
        h = ((r - g) / diff + 4) / 6;
        break;
    }
  }
  l = (max + min) / 2;
  if (max === min) {
    s = 0; // achromatic (no saturation)
  } else {
    s = diff / (1 - Math.abs(2 * l - 1));
  }
  return { h, l, s };
};

const rgb2Hex = (r, g, b) => {
  const appSingleton = new AppSingleton();
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };
  if (appSingleton.isDarkMode) {
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;
  }
  const red = componentToHex(r);
  const green = componentToHex(g);
  const blue = componentToHex(b);
  // console.log("#" + red + green + blue);
  return "#" + red + green + blue;
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

const resizeListener = () => {
  var width = document.documentElement.clientWidth || window.innerWidth || document.body.clientWidth;
  var height = document.documentElement.clientHeight || window.innerHeight || document.body.clientHeight;
  appSingleton.windowSize = { width, height };
};

const getAdaptivFontSize = (fontSize) => {
  const appSingleton = new AppSingleton();
  return Math.floor(fontSize * (appSingleton.fontScale ?? 1.0));
};

const calculateDistance = (touch1, touch2) => {
  var dx = touch1.clientX - touch2.clientX;
  var dy = touch1.clientY - touch2.clientY;
  return Math.sqrt(dx * dx + dy * dy);
};

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let deviceType = "android";

  if (/android/.test(userAgent)) {
    deviceType = "android";
  } else if (/iphone|ipad|ipod|mac/.test(userAgent)) {
    deviceType = "ios";
  } else {
    deviceType = "desktop";
  }
  
  return deviceType;
};

export {
  calculateDistance,
  clearAccessToken,
  formatTime,
  getAccessToken,
  getAdaptivFontSize,
  getDeviceType,
  getRoute,
  updateArrayByKey,
  resizeListener,
  rgb2Hex,
  rgb2Hls,
  serializedChatSonic,
  sha512,
  timestampToDate,
  updateJwt,
  validateEmail,
};
