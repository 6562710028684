import { httpEndpoint } from "../services/backend";

const MODEL_ARS = [
  {
    region: "ssp",
    id: "mei-ho-house",
    name: "石硤尾邨 - 美荷樓",
    entity: {
      ancient: {
        id: "mei-ho-house-ancient",
        // url: "https://survey.reality-connect.tech/models/mei-ho-house/Ancient-c.glb",
        url: `${httpEndpoint}/models/mei-ho-house/Ancient-c.glb`,
        videos: [
          { name: "美荷樓 - 公屋情 (糖水篇)", videoUrl: `${httpEndpoint}/videos/mei-ho-house/美荷樓1--公屋情--糖水篇.mp4` },
          { name: "美荷樓 - 公屋情 (我的家)", videoUrl: `${httpEndpoint}/videos/mei-ho-house/美荷樓2--公屋情--我的家.mp4` },
          { name: "美荷樓 - 公屋情 (麻雀篇)", videoUrl: `${httpEndpoint}/videos/mei-ho-house/美荷樓3--公屋情--麻雀篇.mp4` },
        ],
        desc: "美荷樓的誕生源於1953年12月25日夜晚在石硤尾發生的一場大火（後通稱「石硤尾大火」）。大火發生在石硤尾山邊寮屋區，使接近58,000人喪失家園。為了向災民提供長期的房屋設施，包括美荷樓在內的首批8座六層高的徙置大廈應運而生。為了改善居民生活，邨內其他地方已進行重建，而這座被列為香港二級歷史建築的美荷樓則於2005年關閉，直至2013年才改建成為青年旅舍，並設立了1間生活館。當前，美荷樓主題展覽「歲月留情」更加入口述歷史及各類型展品，以描繪50至60年代基層市民的生活世態，復刻香港精神的原點。",
        // url: "http://192.168.0.220:8004/models/mei-ho-house/Ancient-c.glb",
        defaultScale: 1.5, //0.65,
        // defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0 }, // 0.5 // x = left- right+; z = far- near+
        defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0.2 }, // 0.5
        scale: { x: 1.4, y: 1.4, z: 1.4 },
        position: { x: 0.0, y: 0.0, z: 0.0 },
        positionListing: { x: 0.0, y: 0.5, z: 0.0 },
        roughness: 1.0,
        subItems: ["kerosene_stove_g", "bucket-steel"],
      },
      modern: {
        id: "mei-ho-house-modern",
        // url: "https://survey.reality-connect.tech/models/mei-ho-house/Modern-c.glb",
        url: `${httpEndpoint}/models/mei-ho-house/Modern-c.glb`,
        videos: [
          { name: "美荷樓 - 公屋情 (糖水篇)", videoUrl: `${httpEndpoint}/videos/mei-ho-house/美荷樓1--公屋情--糖水篇.mp4` },
          { name: "美荷樓 - 公屋情 (我的家)", videoUrl: `${httpEndpoint}/videos/mei-ho-house/美荷樓2--公屋情--我的家.mp4` },
          { name: "美荷樓 - 公屋情 (麻雀篇)", videoUrl: `${httpEndpoint}/videos/mei-ho-house/美荷樓3--公屋情--麻雀篇.mp4` },
        ],
        desc: "美荷樓生活館迎來全新面貌的展館，新展覽將會帶大家回到過去，以不同方式讓大眾仿如置身舊日社區，更投入地認識和了解居民生活情景、昔日街坊情和美荷樓的蜕變。歡迎前來參觀，發掘精彩展覽內容！由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。",
        // url: "http://192.168.0.220:8004/models/mei-ho-house/Modern-c.glb",
        defaultScale: 1.5, //0.65,
        // defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0 }, // 0.5 // x = left- right+
        defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0.2 }, // 0.5
        scale: { x: 1.4, y: 1.4, z: 1.4 },
        position: { x: 0.0, y: 0.0, z: 0.0 },
        positionListing: { x: 0.0, y: 0.5, z: 0.0 },
        roughness: 1.0,
        subItems: ["kerosene_stove_g", "bucket-steel"],
      },
    },
  },
  {
    region: "ssp",
    id: "jccac",
    name: "石硤尾工廠大廈",
    entity: {
      ancient: {
        id: "jccac-ancient",
        // url: "https://survey.reality-connect.tech/models/jccac/Ancient-c.glb",
        url: `${httpEndpoint}/models/jccac/Ancient-c.glb`,
        videos: [
          {
            name: "工廠大廈 - 芳菲絮絮 (童工)",
            videoUrl: `${httpEndpoint}/videos/jccac/工廠大廈1--芳菲絮絮--童工.mp4`,
          },
          {
            name: "工廠大廈 - 芳菲絮絮 (學師)",
            videoUrl: `${httpEndpoint}/videos/jccac/工廠大廈2--芳菲絮絮-- 學師.mp4`,
          },
          {
            name: "工廠大廈 - 芳菲絮絮 (100蚊)",
            videoUrl: `${httpEndpoint}/videos/jccac/工廠大廈3--芳菲絮絮--100蚊.mp4`,
          },
        ],
        desc: "JCCAC 的大樓前身是1977 年落成的政府工廈「石硤尾工廠大廈」，當時容納許多謔稱「山寨廠」的家庭式手工業。隨著香港輕工業逐漸式微，工廈單位逐漸被丟空，後得政府提供場地及香港賽馬會慈善信託基金贊助改建項目（並因此命名），這幢舊工廈搖身一變成為樓高九層佔地約二十萬平方呎的重要藝文空間，改建項目並榮獲香港建築師學會2008「全年境內建築大獎」。JCCAC是自負盈虧非牟利慈善團體、香港浸會大學附屬機構，及香港藝術發展局和香港藝術中心的策略夥伴。",
        // url: "http://192.168.0.220:8004/models/jccac/Ancient-c.glb",
        defaultScale: 0.04, // MARK: 0.02
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0.3 }, // 0.5
        scale: { x: 0.05, y: 0.05, z: 0.05 },
        position: { x: 0.0, y: -2.0, z: 0.0 },
        positionListing: { x: 0.0, y: -1.0, z: 0.0 },
        roughness: 1.0,
        subItems: ["sewing-machine-with-table"],
      },
      modern: {
        id: "jccac-modern",
        // url: "https://survey.reality-connect.tech/models/jccac/Modern-c.glb",
        url: `${httpEndpoint}/models/jccac/Modern-c.glb`,
        videos: [
          {
            name: "工廠大廈 - 芳菲絮絮 (童工)",
            videoUrl: `${httpEndpoint}/videos/jccac/工廠大廈1--芳菲絮絮--童工.mp4`,
          },
          {
            name: "工廠大廈 - 芳菲絮絮 (學師)",
            videoUrl: `${httpEndpoint}/videos/jccac/工廠大廈2--芳菲絮絮-- 學師.mp4`,
          },
          {
            name: "工廠大廈 - 芳菲絮絮 (100蚊)",
            videoUrl: `${httpEndpoint}/videos/jccac/工廠大廈3--芳菲絮絮--100蚊.mp4`,
          },
        ],
        desc: "賽馬會創意藝術中心（JCCAC）是香港唯一由整幢廠廈活化而成的垂直藝術村兼藝術中心，有140 個各類型的藝術家和文化團體成立工作室進駐，以視覺藝術為主，並預留近四份之一的工作室培育具潛質而剛起步的新晉藝術家。JCCAC亦是一所對外開放的藝術中心，以親切環境及創意氛圍見稱，定期舉辦展覽、演出、導賞和工作坊等活動。場內設有兩層「藝廊」展覽廳、提供表演活動的「黑盒劇場」，分佈各樓層還有不少特色的展示空間，圍繞中央庭園有茶藝館、咖啡室、創意工藝店等。",
        // url: "http://192.168.0.220:8004/models/jccac/Modern-c.glb",
        defaultScale: 0.04, // MARK: 0.02
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0.3 }, // 0.5
        scale: { x: 0.05, y: 0.05, z: 0.05 },
        position: { x: 0.0, y: -2.0, z: 0.0 },
        positionListing: { x: 0.0, y: -1.0, z: 0.0 },
        subItems: ["sewing-machine-with-table"],
      },
    },
  },
  {
    region: "ssp",
    id: "garden-company",
    name: "嘉頓中心",
    entity: {
      ancient: {
        id: "garden-company-ancient",
        url: `${httpEndpoint}/models/garden-company/ancient.glb`,
        videos: [
          {
            name: "嘉頓 - 芳菲絮絮 (花生妹)",
            videoUrl: `${httpEndpoint}/videos/garden-company/嘉頓1--芳菲絮絮--花生妹.mp4`,
          },
          {
            name: "嘉頓 - 公屋情 (鄰里相助)",
            videoUrl: `${httpEndpoint}/videos/garden-company/嘉頓2--公屋情--鄰里相助.mp4`,
          },
        ],
        desc: "嘉頓有限公司（The Garden Company Limited，簡稱嘉頓）是一家在香港知名且歷史悠久的食品公司，主要生產麵包、餅乾和蛋糕等食品，其著名產品包括“生命麵包”和“雪芳蛋糕”等。公司成立於1926年，由張子芳和黃華岳創立，因在香港植物園討論計劃而得名“Garden”，後在不同地點擴展，並推出富含維生素和礦物質的創新產品如“生命麵包”。",
        defaultScale: 0.5, // MARK: 12.0
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
        scale: { x: 0.5, y: 0.5, z: 0.5 },
        position: { x: 0.0, y: -1.5, z: 0.0 },
        positionListing: { x: 0.0, y: -1.0, z: 0.0 },
      },
    },
  },
  {
    region: "tm",
    id: "poloshan",
    name: "屯門大峽谷 - 菠蘿山",
    entity: {
      ancient: {
        id: "poloshan-ancient",
        url: `${httpEndpoint}/models/poloshan/Ancient-c.glb`,
        videos: [
          { name: "菠蘿山 - 1", videoUrl: `${httpEndpoint}/videos/poloshan/2 mindful walking_F1.mp4`, color: "#FFAC98" },
          { name: "菠蘿山 - 2", videoUrl: `${httpEndpoint}/videos/poloshan/3 Mindful stretching_F1.mp4`, color: "#D0F0C0" },
          // { name: "菠蘿山 - 3", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#B5E2FF" },
        ],
        desc: "菠蘿山，又被稱為「香港的大峽谷」，位於良田坳峽谷，是屯門區的一座小山丘，海拔高達121米。它座落於山景邨和良景邨之間，每年吸引了許多香港人和遊客前來遠足、郊遊和拍照留念。菠蘿山是香港重要的本土文化和歷史景點，承載著豐富的歷史內涵和文化價值。過去，這個地區是屯門地區的農田和果園，而現在則成為人們休閒娛樂的場所。透過虛擬實景技術，我們可以透過手機更深入地了解菠蘿山地貌的獨特之處。",
        defaultScale: 1.0, // MARK: 12.0
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
        scale: { x: 2.0, y: 2.0, z: 2.0 },
        position: { x: 0.0, y: -1.0, z: 0.0 },
        positionListing: { x: 0.0, y: 0.0, z: 0.0 },
        roughness: 1.0,
      },
    },
  },
];

const MODEL_PREVIEWS = [
  {
    region: "ssp",
    id: "jccac",
    key: "jccac-ancient",
    name: "石硤尾大廈 (翻新前)",
    desc: "JCCAC 的大樓前身是1977 年落成的政府工廈「石硤尾工廠大廈」，當時容納許多謔稱「山寨廠」的家庭式手工業。隨著香港輕工業逐漸式微，工廈單位逐漸被丟空，後得政府提供場地及香港賽馬會慈善信託基金贊助改建項目（並因此命名），這幢舊工廈搖身一變成為樓高九層佔地約二十萬平方呎的重要藝文空間，改建項目並榮獲香港建築師學會2008「全年境內建築大獎」。JCCAC是自負盈虧非牟利慈善團體、香港浸會大學附屬機構，及香港藝術發展局和香港藝術中心的策略夥伴。",
    scale: { x: 0.05, y: 0.05, z: 0.05 },
    position: { x: 0.0, y: -2.0, z: 0.0 },
    positionListing: { x: 0.0, y: -1.0, z: 0.0 },
    roughness: 1.0,
  },
  {
    region: "ssp",
    id: "jccac",
    key: "jccac-modern",
    name: "石硤尾大廈 (翻新後)",
    desc: "賽馬會創意藝術中心（JCCAC）是香港唯一由整幢廠廈活化而成的垂直藝術村兼藝術中心，有140 個各類型的藝術家和文化團體成立工作室進駐，以視覺藝術為主，並預留近四份之一的工作室培育具潛質而剛起步的新晉藝術家。JCCAC亦是一所對外開放的藝術中心，以親切環境及創意氛圍見稱，定期舉辦展覽、演出、導賞和工作坊等活動。場內設有兩層「藝廊」展覽廳、提供表演活動的「黑盒劇場」，分佈各樓層還有不少特色的展示空間，圍繞中央庭園有茶藝館、咖啡室、創意工藝店等。",
    scale: { x: 0.05, y: 0.05, z: 0.05 },
    position: { x: 0.0, y: -2.0, z: 0.0 },
    positionListing: { x: 0.0, y: -1.0, z: 0.0 },
  },
  {
    region: "ssp",
    id: "garden-company",
    key: "garden-company-ancient",
    name: "嘉頓中心",
    desc: "嘉頓有限公司（The Garden Company Limited，簡稱嘉頓）是一家在香港知名且歷史悠久的食品公司，主要生產麵包、餅乾和蛋糕等食品，其著名產品包括“生命麵包”和“雪芳蛋糕”等。公司成立於1926年，由張子芳和黃華岳創立，因在香港植物園討論計劃而得名“Garden”，後在不同地點擴展，並推出富含維生素和礦物質的創新產品如“生命麵包”。",
    scale: { x: 0.5, y: 0.5, z: 0.5 },
    position: { x: 0.0, y: -1.5, z: 0.0 },
    positionListing: { x: 0.0, y: -1.0, z: 0.0 },
  },
  {
    region: "ssp",
    id: "mei-ho-house",
    key: "mei-ho-house-ancient",
    name: "石硤尾邨 - 美荷樓 (翻新前)",
    desc: "美荷樓的誕生源於1953年12月25日夜晚在石硤尾發生的一場大火（後通稱「石硤尾大火」）。大火發生在石硤尾山邊寮屋區，使接近58,000人喪失家園。為了向災民提供長期的房屋設施，包括美荷樓在內的首批8座六層高的徙置大廈應運而生。為了改善居民生活，邨內其他地方已進行重建，而這座被列為香港二級歷史建築的美荷樓則於2005年關閉，直至2013年才改建成為青年旅舍，並設立了1間生活館。當前，美荷樓主題展覽「歲月留情」更加入口述歷史及各類型展品，以描繪50至60年代基層市民的生活世態，復刻香港精神的原點。",
    scale: { x: 1.4, y: 1.4, z: 1.4 },
    position: { x: 0.0, y: 0.0, z: 0.0 },
    positionListing: { x: 0.0, y: 0.0, z: 0.0 },
    roughness: 1.0,
  },
  {
    region: "ssp",
    id: "mei-ho-house",
    key: "mei-ho-house-modern",
    name: "石硤尾邨 - 美荷樓 (翻新後)",
    desc: "美荷樓生活館迎來全新面貌的展館，新展覽將會帶大家回到過去，以不同方式讓大眾仿如置身舊日社區，更投入地認識和了解居民生活情景、昔日街坊情和美荷樓的蜕變。歡迎前來參觀，發掘精彩展覽內容！由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。",
    scale: { x: 1.4, y: 1.4, z: 1.4 },
    position: { x: 0.0, y: 0.0, z: 0.0 },
    positionListing: { x: 0.0, y: 0.0, z: 0.0 },
    roughness: 1.0,
  },
  {
    region: "tm",
    id: "poloshan",
    key: "poloshan-ancient",
    name: "屯門大峽谷 - 菠蘿山",
    desc: "菠蘿山，又被稱為「香港的大峽谷」，位於良田坳峽谷，是屯門區的一座小山丘，海拔高達121米。它座落於山景邨和良景邨之間，每年吸引了許多香港人和遊客前來遠足、郊遊和拍照留念。菠蘿山是香港重要的本土文化和歷史景點，承載著豐富的歷史內涵和文化價值。過去，這個地區是屯門地區的農田和果園，而現在則成為人們休閒娛樂的場所。透過虛擬實景技術，我們可以透過手機更深入地了解菠蘿山地貌的獨特之處。",
    scale: { x: 2.0, y: 2.0, z: 2.0 },
    position: { x: 0.0, y: -1.0, z: 0.0 },
    positionListing: { x: 0.0, y: -1.0, z: 0.0 },
    roughness: 1.0,
  },
  {
    id: "bucket-steel",
    key: "bucket-steel",
    name: "舊金屬桶",
    desc: "1963年，香港發生旱災，香港政府實施了歷史上最嚴格的制水措施，由最初每日供水3小時，1個月後改為每4天供水一次，每次4小時，足足實施了一年。制水期間，便攜儲水用具需求大增，有人看準商機，收集不同的桶加工，其中最普遍的，便是從雜貨鋪買來食油罐，鋸去頂磨平鋒利的接口，再用長木條穿過，搥打成固定手抽。康齡劇社成員胡渠分享，制水時她16歲，一家人居於唐3樓，因水壓不夠力，所以她也要到街上裝水，她家也有購入這些改裝的油桶裝水，價錢約1元幾角。",
    scale: { x: 1.0, y: 1.0, z: 1.0 },
    position: { x: 0.0, y: -2.0, z: 0.0 },
    positionListing: { x: 0.0, y: -2.0, z: 0.0 },
    metalness: 1.0,
    roughness: 0.1,
  },
  {
    id: "bucket-plastic",
    key: "bucket-plastic",
    name: "舊鐵桶",
    desc: "1963年，香港發生旱災，香港政府實施了歷史上最嚴格的制水措施，由最初每日供水3小時，1個月後改為每4天供水一次，每次4小時，足足實施了一年。制水期間，便攜儲水用具需求大增，有人看準商機，收集不同的桶加工，其中最普遍的，便是從雜貨鋪買來食油罐，鋸去頂磨平鋒利的接口，再用長木條穿過，搥打成固定手抽。康齡劇社成員胡渠分享，制水時她16歲，一家人居於唐3樓，因水壓不夠力，所以她也要到街上裝水，她家也有購入這些改裝的油桶裝水，價錢約1元幾角。",
    scale: { x: 2.0, y: 2.0, z: 2.0 },
    position: { x: 0.0, y: -2.0, z: 0.0 },
    positionListing: { x: 0.0, y: -2.0, z: 0.0 },
    metalness: 1.0,
    roughness: 0.1,
  },
  {
    id: "sewing-machine-with-table",
    key: "sewing-machine-with-table",
    name: "上一代手動衣車",
    desc: "五十年代的衣車大多都是腳踏或手搖式，車身用鐵做，較耐用。當時衣車開需求大，但價錢昂貴，最出名的勝家，一部要百多二百元，當時一般人月薪都只有數拾元，所以很多時都是買二手衣車。圖中的衣車由已故的康齡劇社演員黃素珍婆婆歲捐出的。素珍曾分享衣車是於七十年代初以二手購入，當時她約50歲，自己一手一腳用以衣車縫製妹(女童)裙在深水埗街邊擺賣。",
    scale: { x: 3.0, y: 3.0, z: 3.0 },
    position: { x: 0.0, y: -3.0, z: 0.0 },
    positionListing: { x: 0.0, y: -3.0, z: 0.0 },
    metalness: 1.0,
    roughness: 0.01,
  },
  {
    id: "kerosene_stove_g",
    key: "kerosene_stove_g",
    name: "火水爐",
    desc: "火水爐曾在香港歷史上廣泛使用，約20年前。它以煤油為燃料，提供明火烹飪，曾是取代柴火的便利工具。雖然在現代建築中已被煤氣和電磁爐取代，但在一些發展中國家和偏遠地區仍然常見，特別適合戶外活動使用。",
    scale: { x: 1.0, y: 1.0, z: 1.0 },
    position: { x: 0.0, y: 0.0, z: 0.0 },
    positionListing: { x: 0.0, y: -1.0, z: 0.0 },
    metalness: 0.0,
    roughness: 1,
  },
  {
    id: "kerosene_lamp",
    key: "kerosene_lamp",
    name: "火水燈",
    desc: "火水燈是20世纪早期至中期在香港广泛使用的照明设备，使用煤油或灯油作为燃料，为人们提供了必要的照明，在城市生活中逐渐被电灯所取代。",
    scale: { x: 1.0, y: 1.0, z: 1.0 },
    position: { x: 0.0, y: 0.0, z: 0.0 },
    positionListing: { x: 0.0, y: 0.0, z: 0.0 },
    metalness: 1.0,
    roughness: 0.1,
  },
  {
    id: "lpg_tank",
    key: "lpg_tank",
    name: "石油氣罐",
    desc: "石油氣煮食是香港历史上常见的烹饪方式，利用石油气作为燃料进行烹饪，曾在家庭和餐馆中广泛使用，为香港人提供便利的烹饪方式。",
    scale: { x: 1.0, y: 1.0, z: 1.0 },
    position: { x: 0.0, y: 0.0, z: 0.0 },
    positionListing: { x: 0.0, y: 0.0, z: 0.0 },
    metalness: 1.0,
    roughness: 0.1,
  },
];

export { MODEL_ARS, MODEL_PREVIEWS };
