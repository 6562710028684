import React, { useState, useEffect, useRef, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Card, Fab, Dialog, DialogContent, Grid, IconButton, Typography, Divider } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos, ColorLens, HelpOutline, Home } from "@mui/icons-material";
import { BounceLoader } from "react-spinners";
import { Canvas } from "@react-three/fiber";
import { backendEndPoint } from "../services/backend";
import { OrbitControls } from "@react-three/drei";
import { MODEL_ARS, MODEL_PREVIEWS } from "../data/ar-data";
import { ModelPreview } from "../components/ModelPreview";
import { IntroductoryAvatar } from "../components/IntroductoryAvatar";
import { AppSingleton } from "../services/user_config";
import { calculateDistance, getDeviceType, getRoute } from "../utils/CommonUtils";

// const getARDesc = ({ id, key }) => {
//   let _desc = ". . .";
//   if (id && key) {
//     const _dictKey = key.split("-").pop();
//     const results = MODEL_ARS.filter((_ar) => _ar.id === id && _ar.entity[_dictKey].id === key);
//     if (results.length > 0) {
//       _desc = results[0].entity[_dictKey].desc;
//     }
//   }
//   return _desc;
// };

const ListingPage = () => {
  const { region } = useParams();
  const appSingleton = new AppSingleton();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [arDict, setArDict] = useState(null);
  const [glbDict, setGlbDict] = useState(null);
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const [isNew, setIsNew] = useState(false);
  const [isDescOn, setIsDescOn] = useState(false);

  useEffect(() => {
    setArDict(null);
    setTimeout(() => {
      // const _modelDicts = MODEL_PREVIEWS.filter((m) => m.region === region);
      // const _index = Math.abs(currentIndex % _modelDicts.length);
      // const _glbDict = _modelDicts[_index];
      const _entities = MODEL_ARS.filter((m) => m.region === region);
      const _index = Math.abs(currentIndex % _entities.length);
      // console.log("_index");
      // console.log(_index);
      let _entityDict = isNew ? _entities[_index].entity.modern : _entities[_index].entity.ancient;
      // console.log("_entityDict");
      // console.log(_entityDict);
      if (_entityDict) {
        _entityDict = { ..._entityDict, key: _entityDict.id };
        setArDict(_entityDict);
      }
    }, 500);
  }, [currentIndex, region, isNew]);

  return (
    <Box sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 5,
          backgroundColor: appSingleton.themeColor.foreColor.main,
        }}
      >
        <Fab
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            backgroundColor: "#FFF",
            color: `${appSingleton.themeColor.foreColor.main}`,
          }}
          onClick={() => {
            window.location.href = backendEndPoint;
          }}
        >
          <Home />
        </Fab>
        <Box>
          <Typography sx={{ fontSize: 36, color: "#FFF", textAlign: "center" }}>
            歡迎來到{region === "tm" ? "屯門菠蘿山" : "深水埗"}
          </Typography>
          <Typography sx={{ fontSize: 24, color: "#FFF", textAlign: "center" }}>{arDict?.name ?? ""}</Typography>
          <Divider sx={{ ml: 6, mr: 6, mt: 2, mb: 2 }} color="#FFF" />
        </Box>
        <Box sx={{ width: "50%", display: "flex", justifyContent: "space-around" }}>
          {deviceType === "ios" ? (
            <Button
              variant="contained"
              size="large"
              sx={{ borderRadius: "24px", boxShadow: 4 }}
              onClick={() => {
                if (deviceType === "ios") {
                  // window.open(`/models/preview/${glbDict?.key}.usdz`, "_blank", "");
                  window.open(`/models/preview/${arDict?.id}.usdz`, "_blank", "");
                }
              }}
            >
              沉浸模式
            </Button>
          ) : null}

          <Button
            variant="contained"
            size="large"
            sx={{
              borderRadius: "24px",
              boxShadow: 4,
              // backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
              // color: "#FFF",
              // "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
            }}
            onClick={() => {
              navigate(`${getRoute("ar")}/${region}/${arDict.id}`);
            }}
          >
            卡牌追蹤模式
          </Button>
        </Box>
      </Box>
      <Fab
        sx={{
          position: "absolute",
          top: "50%",
          left: 8,
          backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          color: "#FFF",
          "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
        }}
        onClick={() => setCurrentIndex((prev) => prev - 1)}
      >
        <ArrowBackIosNew />
      </Fab>
      <Fab
        sx={{
          position: "absolute",
          top: "50%",
          right: 8,
          backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          color: "#FFF",
          "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
        }}
        onClick={() => setCurrentIndex((prev) => prev + 1)}
      >
        <ArrowForwardIos />
      </Fab>

      <Fab
        color={isNew ? "primary" : "#AAAAAA"}
        aria-label="Furnish"
        sx={{
          position: "absolute",
          right: 16,
          top: "24vh",
          backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          "&:hover": {
            backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          },
        }}
        onClick={() => {
          setIsNew((_prev) => !_prev);
        }}
      >
        <ColorLens />
      </Fab>
      <Grid container spacing={2} sx={{ position: "absolute", top: "24vh", left: 16 }}>
        {arDict?.subItems?.map((_item) => (
          <Grid item key={`subItems-${_item}`}>
            <Fab
              sx={{ width: "72px", height: "72px" }}
              onClick={() => {
                const _dispaly_models = MODEL_PREVIEWS.filter((_m) => _m.key === _item);
                if (_dispaly_models.length > 0) {
                  setGlbDict(_dispaly_models[0]);
                }
              }}
            >
              <img
                key={`subItems-${_item}`}
                src={`/models/preview/${_item}.png`}
                alt={""}
                style={{ width: "60px", height: "auto" }}
              />
            </Fab>
          </Grid>
        ))}
      </Grid>

      <Suspense
        fallback={
          <Box sx={{ width: "100%", height: "80vh", top: "20vh" }}>
            <BounceLoader
              color="white"
              cssOverride={{ display: "block", margin: "0 auto", borderColor: "red" }}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        }
      >
        <Canvas ref={canvasRef} style={{ width: "100%", height: "80vh", top: "20vh" }}>
          <ambientLight intensity={2.0} />
          <pointLight position={[2, 2, 0]} intensity={100} decay={2} distance={30} />
          {arDict ? <ModelPreview glbDict={arDict} canvasRef={canvasRef} fmPage="listing" /> : null}
          <OrbitControls />
        </Canvas>
      </Suspense>

      {/* <Fab
        color={isNew ? "primary" : "#AAAAAA"}
        aria-label="Description on off"
        sx={{
          position: "absolute",
          right: 16,
          bottom: 16,
          backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          "&:hover": {
            backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          },
        }}
        onClick={() => {
          setIsDescOn((prev) => !prev);
        }}
      >
        <HelpOutline />
      </Fab> */}

      <IntroductoryAvatar
        sx={{
          position: "absolute",
          bottom: 32,
          right: 16,
        }}
        {...arDict}
      />

      {/* {isDescOn ? (
        <Card
          sx={{
            position: "absolute",
            bottom: 32,
            width: "90%",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 5,
          }}
        >
          <Typography sx={{ fontSize: 16, m: 4 }}>{arDict?.desc}</Typography>
        </Card>
      ) : null} */}

      <Dialog
        open={!!glbDict}
        onClose={() => setGlbDict(null)}
        PaperProps={{
          style: { width: "95%", backgroundColor: "#cccccc" },
        }}
      >
        <DialogContent>
          <Canvas ref={canvasRef} style={{ width: "100%", height: "80vh" }}>
            <ambientLight intensity={2.0} />
            <pointLight position={[2, 2, 0]} intensity={100} decay={2} distance={30} />
            {glbDict ? <ModelPreview glbDict={glbDict} canvasRef={canvasRef} /> : null}
            <OrbitControls />
          </Canvas>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const f = () => {};

export { ListingPage, f };
